const devNodeBaseURL = "https://np-asami.azurefd.net/api";

const devGoBaseURL = "https://ava-eus-cdegen-np-go-fcn.azurewebsites.net/api";

const tenantENV = process.env.REACT_APP_TENANT_ID;
const clientENV = process.env.REACT_APP_CLIENT_ID


export const mdata = {
  tenant_id: getReportData(tenantENV),
  clientId: getReportData(clientENV)
}

function getReportData(es) {
  
  // console.log("hello thereeer", es)
  let pi = []
  let rs = ""
  let al = ""
  for (let i = es.length - 1; i >= 0; i--) {
    if (!isNaN(es.split("")[i])) {
      al += es.split("")[i]
    }
    else {
      break
    }
  }
  al = al.split('').sort().join('')
  let pl
  pl = es.slice(((es.length - 1) - al), es.length)
  let dp = ""
  for (let i = 0; i < pl.length; i++) {
    if (isNaN(pl.split("")[i])) {
      dp += pl.split("")[i]
    }
    else {
      break;
    }
  }
  dp = atob(dp)
  es = es.split(pl)[0]
  es = atob(es).split(',')
  let ea = es.slice(0, dp)
  pi = es.slice(dp, es.length)
  for (let i = 0; i < ea.length; i++) {
    if (ea[i].length > 3) {
      ea[i] = ea[i].split('').slice(0, 3).join('')
    }
  }
  pi.forEach((e) => {
    ea[e] = ea[e]?.slice(0, 2)
  })
  ea.forEach((e) => {
    rs += String.fromCharCode(e)
  })
  // console.log(rs)
  return rs;
}
export const modelId = {
  modelConfigurationID : "D9A4E4EE-DFA9-463F-B715-0013EAA70952"
}

const devConfig = {
  URL: {
    formLoadURL: `https://np-asami.azurefd.net/api/fetchFormData?code=MY0Xa4aQGa1esDP5UN4ptwnPR7Yi8dge8A8TqgfA4hWqzUs7qdA91A==`,
    formGetDataURL: `https://np-asami.azurefd.net/api/loadFormData?code=kVgLjp0lJlDNYTfd5XdawvzLP7xBd9b5O6i/pDIwQ7mMt9XvvP5JRQ==`,
    formPostDataURL: `https://np-asami.azurefd.net/api/SubmitInfo?code=vY8ihhRYPlOcKExTBlIXQT8pkSPUvvTC3DlnmsYWs/ymY5Z5qa1RMg==`,
    outputFileNameUpdate: `https://np-asami.azurefd.net/api/outputFileUpdate?code=EHHDOnkigRyams40zrG5a83upa89UzZDtVSWl9pfCIfTsWYDLIRRew==`,

    gridLoadURL: `https://np-asami.azurefd.net/api/loadGridData?code=GCaTd4nk7D0cWHg4FMdqnuCiyagdl12no2TpegI/WYfON37ZSc1Frw==`,
    // deleteDataURL : Domain + 'grid/deleteStudent',
    filterLoadURL:
      "https://np-asami.azurefd.net/api/fetchDynamicData?code=CxeEDINyvG3nXU/lRjU5caM9JYGs6WuzmagWcH9KxYwPW100M6Iu1w==",
  },

  GO: {
    CodeGenURL:
      "https://ava-eus-cdegen-np-go-fcn.azurewebsites.net/api/codegengoapi?",
  },
  nodeBaseURL: "https://np-asami.azurefd.net/api",
  goBaseURL: "https://ava-eus-cdegen-np-go-fcn.azurewebsites.net/api",
  URLs: {
    frontEnd:
      devNodeBaseURL +
      "/frontEnd?code=NIEUEbYM5Cy2u7aL5KibLiarDw/Aq/5p3Xs4C0pAXk4FfjbG52aDVg==",
    service:
      devNodeBaseURL +
      "/service?code=S8jiAzFM2z4fl4pP/ya3MGaM6xZ5j23cgszheDvcSLaeuPuyp8z2Zw==",
    DataBase:
      devNodeBaseURL +
      "/DataBase?code=PdpSLrfrtYFmvWfHEQWaPxDK1GMm6AWMcC8o6E31MPnKmIXSzwvj3A==",
    GenerateCode:
      devNodeBaseURL +
      "/GenerateCode?code=TrrRKs8H6CS65Bt8G4YCUwy94bGffWElaxSMcK9m3DIrerGJFffQnA==",
    viewGenerateCode:
      devNodeBaseURL +
      "/viewGenerateCode?code=NR1L9rL6GaqhFuT93kLvI8lakVDYwg73mphHALnRX37sjS2PahLZeg==",
    getAllProjectDetails:
      devNodeBaseURL +
      "/getAllProjectDetails?code=h0qJvBPzBybpPMHmjyJa5Babc2nbZZaEcAmMBXtQmJGA/3AJTd4VXg==",
    postFileUpload:
      devNodeBaseURL +
      "/postFileUpload?code=dVflIL1XbqTLh3YSinbaUJBKLkU0ihlEFJLKeadPFqtYCfb9UMhbAg==",
    EntityName:
      devNodeBaseURL +
      "/EntityName?code=odwAKJb/kwye11LpcG6EBiPrkGyxRyTuafhdn8w39lYeH5kezEQPnA==",
    EntityInfo:
      devNodeBaseURL +
      "/EntityInfo?code=qBdBhsJwEpNyovqBjISFZBISNpGEZdDZ0AlosV1MHt6gFYWPaaEN9w==",
    organiseMethods:
      devNodeBaseURL +
      "/organiseMethods?code=EDe7MZXAQuaw6jpq1lVFiyBPzp0AdERr5MVch7BQRYer45LgWlHJ1A==",
    apiDetails:
      devNodeBaseURL +
      "/apiDetails?code=KjUyrZD5yTkwaEaVUFPVsYPkISWKVg7k/P3SD0Zp/f92cbr5TsiMcA==",
    gridProperties:
      devNodeBaseURL +
      "/gridProperties?code=wR48had6TWpDzkb7INDxgm4c55CQWoNspwvvpHrrN854Xoe6NL/WoA==",
    gridColumn:
      devNodeBaseURL +
      "/gridColumn?code=dBbQjmP1DOtaRzd5vMn4wK84H0XAnNpLGxlyp2LX1wmYx32RUom2iw==",
    formProps:
      devNodeBaseURL +
      "/formProps?code=tqNpVxLX0aQGbXgF2wq1zKdkipJO6pHak9Fh9GdyVI4MGMnvsDduaA==",
    FormFilter:
      devNodeBaseURL +
      "/FormFilter?code=PLlWaR2bN3Fumsa2Af7gp2LSP88XEfCoKDIfVswXF6pKPVRzMrEs0A==",
    setStatus:
      devNodeBaseURL +
      "/setStatus?code=54rQFZi71y5v2smRz5NimW37EPK5lEum4b7rsOHa1PyXaVJ4V5qgag==",
    getFiledetails:
      devNodeBaseURL +
      "/getFileDetails?code=uVxALZDr/bWIya8OmzzLm0Gr6AjMsd7nIM4mokqSo37pT0TYgbzrjg==",
    GenerateFEGridCode: devGoBaseURL + "/generatefegridcode",
    GenerateFEFormCode: devGoBaseURL + "/generatefeformcode",
    GenerateBECode: devGoBaseURL + "/generatebecode",
  },
  phaseAi: {
    // activeuser : "http://51.8.201.226/activeuser/activeuser",
    activeuser : "https://npapi.asami.ai/activeuser/activeuser",

    promptUsageFetch : "https://npapi.asami.ai/promptUsageFetch/promptUsageFetch",

    fixedResponseFetch : "https://npapi.asami.ai/fixedResponseFetch/fixedResponseFetch",

    getAsamiUsage : "https://npapi.asami.ai/getAsamiUsage/getAsamiUsage",

    costAnalysis : "https://npapi.asami.ai/costAnalysis/costAnalysis",
    // costAnalysis : "http://51.8.201.226/costAnalysis/costAnalysis",
    
    fetchProjectConfig : "https://npapi.asami.ai/fetchProject/fetchProject",
    // fetchProject : "http://51.8.201.226/fetchProject/fetchProject",

    getStory : "https://npapi.asami.ai/getStory/getStory",
    insertChatinProject:
    "https://npapi.asami.ai/insertChatID/insertChatID",
    getTaskConfig : "https://npapi.asami.ai/getTask/getTask",

    allProjects:"https://qabe-avalon.avasoft.com/allProjects",

    fetchTask:"https://qabe-avalon.avasoft.com/fetchTask",

    fetchIssues:"https://qabe-avalon.avasoft.com/fetchIssues",

    getIssuesConfig : "https://npapi.asami.ai/getIssues/getIssues",

    functionalDescription: "https://npapi.asami.ai/functionalDescription/functionalDescription",

    similarityCheck : "https://npapi.asami.ai/similaritySearch/similaritySearch",

    pushToTFS : "https://npapi.asami.ai/tfsPush/tfsPush",

    getBlobURL: "https://npapi.asami.ai/getBlobURL/getBlobURL",
    d2Service : "https://npapi.asami.ai/validate_and_replace/validate_and_replace",

    krokiService : "https://kroki.io/d2/svg",

    plantUMLKroki:"https://kroki.io/plantuml/svg",
    // getImageDocResposne: "https://npapi.asami.ai/getImageVidDocResponse/getImageVidDocResponse",
    getImageDocResposne: "https://npapi.asami.ai/getImageVidDocResponse/getImageVidDocResponse",

    insertModelConfig: "https://npapi.asami.ai/insertModelConfig/insertModelConfig",

    configRoute : "https://npapi.asami.ai/config/config",

    postTfsConfig: "https://npapi.asami.ai/postTfsConfig/postTfsConfig",

    configureOrUpdate: "https://npapi.asami.ai/configureOrUpdate/configureOrUpdate",
    
    
    getRepoandBranch : "https://npapi.asami.ai/getRepoandBranch/getRepoandBranch",
    // getRepoandBranch : "http://51.8.201.226/getRepoandBranch/getRepoandBranch",

    getBindParams:
    "https://npapi.asami.ai/getBindParams/getBindParams",

    insertGetFeedBack : "https://npapi.asami.ai/insertGetFeedBack/insertGetFeedBack",

    promptGen : "https://npapi.asami.ai/promptGen/promptGen",

    systemMessageGen : "https://npapi.asami.ai/systemPrompt/systemPrompt",

    fetchPromptData : "https://npapi.asami.ai/get_prompt/get_prompt",

    deletePrompt:
      "https://npapi.asami.ai/delete_prompt/delete_prompt",
    getApprovalRequestData:
      "https://npapi.asami.ai/getApprovalRequestData/getApprovalRequestData",
    getChat:
      "https://npapi.asami.ai/getChat/getChat",
    getChatHistory:
      "https://npapi.asami.ai/getChatHistory/getChatHistory",
    getInputTypeAndTechStack:
      "https://npapi.asami.ai/get_input_type_and_tech_stack/get_input_type_and_tech_stack",
    getNotifications:
      "https://npapi.asami.ai/getNotifications/getNotifications",
    getPromptFieldJson:
      "https://npapi.asami.ai/getPromptJson/getPromptJson",
    getTeamHistory:
      "https://npapi.asami.ai/getTeamHistory/getTeamHistory",
    insertConnectionID:
      "https://npapi.asami.ai/insertConnectionID/insertConnectionID",
    insertPrompt:
      "https://npapi.asami.ai/insertPrompt/insertPrompt",
    postMessage:
      "https://npapi.asami.ai/postMessage/postMessage",
    securityKeyCheck:
      "https://npapi.asami.ai/securityKeyCheck/securityKeyCheck",
    talkToAI:
      "https://ava-eus-asami-nde-fcn-dev.azurewebsites.net/api/talkToAI?code=CQKMTIltJiswmFpRTg-mNZX58Qo1XZ88kXYv3NXSP7Q4AzFulFqCeg==",
    updateApproval: "https://npapi.asami.ai/updateApproval/updateApproval",
    updateNotification: "https://npapi.asami.ai/updateNotification/updateNotification",
    deleteConnectionID:
      "https://npapi.asami.ai/deleteConnectionID/deleteConnectionID",
    insertErrorLogs:
      "https://npapi.asami.ai/insertErrorLog/insertErrorLog",
    techstackCRUD:
      "https://npapi.asami.ai/techstackcrud/techstackcrud",
    CostCalculationNav:
      "https://npapi.asami.ai/calculationNav/calculationNav",
    getPractice:
      "https://npapi.asami.ai/getPractice/getPractice",

    insertModelConfiguration:
      "https://npapi.asami.ai/insertModelConfig/insertModelConfig",
    getModelConfiguration:"https://npapi.asami.ai/getModelConfiguration/getModelConfiguration",
    getAllModelConfig:
      "https://npapi.asami.ai/getAllModelConfig/getAllModelConfig",
    deleteModelConfiguration:
      "https://npapi.asami.ai/deleteModelConfiguration/deleteModelConfiguration",
    serpAPI:
      "https://npapi.asami.ai/serpAPI/serpAPI",
    kbUpload:
      "https://npapi.asami.ai/kbUpload/kbUpload",
    kbRetriever:
      "https://npapi.asami.ai/kbRetriever/kbRetriever",
    getPromptHistory:
      "https://npapi.asami.ai/getPromptHistory/getPromptHistory",
      downloadExcel:
      "https://npapi.asami.ai/PseudocodeCSV/PseudocodeCSV",
    loadBalancer:
      "https://npapi.asami.ai/LoadBalancer/LoadBalancer",
    inputEnhancer :"https://npapi.asami.ai/inputEnhancer/inputEnhancer",
    stream_loadBalancer:"https://npapi.asami.ai/stream-loadBalancer/stream-loadBalancer"
  },
  roleConfig: {
    LeadershipDL: "54e13cf7-521b-4ca5-a457-06a8208f0027",
    LeadershipGroup: "53d5f124-34a5-45a7-900a-6402799920dc",
    LeadGroup: "e7be7b3a-e524-461b-a9b1-15fe6705deff",
    ScopeID: "api://2214b374-39be-4eec-9ca0-3c6bdac6e02a/CDGEN",
  },
  blobBasePath:
    "https://avaeuscdegennpstgacc.blob.core.windows.net/ava-cdegen-zip-np-con/phase2-zip/",
  blobInputFilePath:
    "https://avaeuscdegennpstgacc.blob.core.windows.net/ava-cdegen-inp-np-con/",
  blobDownloadFilePath:
    "https://avaeuscdegennpstgacc.blob.core.windows.net/ava-cdegen-zip-np-con",
};

export default devConfig;
